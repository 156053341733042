/* You can add global styles to this file, and also import other style files */
/*@import "assets/styles/all.min.css";*/
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import '~bootstrap/scss/bootstrap';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
.container{
  max-width: 99%
}
.splash-screen {
  background-color: transparent;
  height: 100%;
}

.splash-loader,
.splash-loader::before,
.splash-loader::after {
  left: 50%;
  top: 50%;
  margin-left: -30px;
  margin-top: -30px;
  position: absolute;
  vertical-align: middle;
  background: transparent;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  z-index: 1;
}
.splash-loader::before {
  content: "";
  animation: bounce 1.5s infinite;
}

.splash-loader::after {
  content: "";
  animation: bounce 1.5s -0.4s infinite;
}

.splash-logo {
  left: 50%;
  top: 50%;
  margin-left: -20px;
  margin-top: -20px;
  z-index: 2;
  position: absolute;
}
select option:checked,
select option:hover {
  box-shadow: 0 0 10px 100px #006500 inset;
}
@media (max-width: 500px){
  .responsive-hide{
    display: none;
  }

}
